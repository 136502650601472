export const popup = () => {
  let popupEl = document.querySelector('.cmswt-InstantSearchPopup')
  let isPopupActive = false

  //open popup
  const openPopup = () => {
    popupEl.classList.add('cmswt-InstantSearchPopup--active')
    //trigger focus for input
    popupEl.querySelector('.ais-SearchBox-input').focus()

    isPopupActive = true
  }

  //close popup
  const closePopup = () => {
    isPopupActive = false
    popupEl.classList.remove('cmswt-InstantSearchPopup--active')
  }

  //this is for popup icon / custom triggers
  let popupTriggers = document.querySelectorAll('.cm-swt-instant-search-popup--trigger')
  if (popupTriggers.length > 0) {
    popupTriggers.forEach((popupTrigger) => {
      //trigger popup
      if (popupTrigger !== null) {
        popupTrigger.addEventListener('click', (e) => {
          e.preventDefault()
          openPopup()
        })
      }
    })
  }

  //this is when WordPress search forms are clicked or typed into
  let blockWidgetSearchForms = document.querySelectorAll('.wp-block-search')
  if (blockWidgetSearchForms.length > 0) {
    blockWidgetSearchForms.forEach((blockWidgetSearchForm) => {
      let searchInput = blockWidgetSearchForm.querySelector('.wp-block-search__input')
      //on submit
      blockWidgetSearchForm.addEventListener('click', (e) => {
        e.preventDefault()
        openPopup()
      })

      //on click on search input
      searchInput.addEventListener('click', (e) => {
        openPopup()
      })
    })
  }

  //this is for old school forms
  let traditionalSearchForms = document.querySelectorAll('.search-form')
  if (traditionalSearchForms.length > 0) {
    traditionalSearchForms.forEach((traditionalSearchForm) => {
      traditionalSearchForm.addEventListener('click', (e) => {
        e.preventDefault()
        openPopup()
      })
    })
  }

  if (popupEl !== null) {
    //trigger popup for customizer
    window.addEventListener('message', (event) => {
      if (event.data?.cmswtOpenSearch) {
        openPopup()
      } else if (event.data?.cmswtOpenSearch === false) {
        closePopup()
      }
    })

    //on press of escape key
    document.addEventListener('keydown', (e) => {
      if (e.type === 'keydown' && e.keyCode === 27 && isPopupActive) {
        closePopup()
      }
    })

    //on click of overlay
    popupEl.addEventListener('click', (e) => {
      if (e.target === popupEl && isPopupActive) {
        closePopup()
      }
    })

    //on click close button
    let popupCloseEl = document.querySelector('.cmswt-InstantSearchPopup--closeIcon')
    popupCloseEl.addEventListener('click', (e) => {
      e.preventDefault()

      if (!isPopupActive)
        return

      closePopup()
    })
  }

}

document.addEventListener('DOMContentLoaded', () => {
  popup()
})